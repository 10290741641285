<template>
  <div class="pd10">
    <el-card class="custom-card">
      <div slot="header" class="clearfix">
        <span class="border-l relative pdL10">{{$route.meta.title}}</span>
        <el-button class="right" type="text" @click="add">添加</el-button>
      </div>
      <el-table :data="tableData" border stripe v-loading="loading" style="width: 100%;">
        <el-table-column prop="houseName" :show-overflow-tooltip="true" header-align="center" align="center"
          label="仓房名称">
        </el-table-column>
        <el-table-column prop="cangName" :show-overflow-tooltip="true" header-align="center" align="center"
          label="仓房类型"></el-table-column>
        <el-table-column prop="cangLong" :show-overflow-tooltip="true" header-align="center" align="center" label="仓长">
        </el-table-column>
        <el-table-column prop="cangWide" :show-overflow-tooltip="true" header-align="center" align="center" label="仓宽">
        </el-table-column>
        <el-table-column prop="liangHeight" :show-overflow-tooltip="true" header-align="center" align="center"
          label="粮高">
        </el-table-column>
        <el-table-column prop="designReserves" :show-overflow-tooltip="true" header-align="center" align="center"
          label="设计储量（吨）">
        </el-table-column>
        <el-table-column prop="actualReserves" :show-overflow-tooltip="true" header-align="center" align="center"
          label="实际储量（吨）">
        </el-table-column>
        <el-table-column prop="storekeeper" :show-overflow-tooltip="true" header-align="center" align="center"
          label="保管员"></el-table-column>
        <el-table-column prop="liangVarieties" :show-overflow-tooltip="true" header-align="center" align="center"
          label="粮食品种">
        </el-table-column>
        <el-table-column prop="reservesProperty" :show-overflow-tooltip="true" header-align="center" align="center"
          label="储量性质">
        </el-table-column>
        <el-table-column prop="enterTime" :show-overflow-tooltip="true" header-align="center" align="center"
          label="入仓时间"></el-table-column>
        <el-table-column prop="enterDew" :show-overflow-tooltip="true" header-align="center" align="center"
          label="入仓水分"></el-table-column>
        <el-table-column prop="producingArea" :show-overflow-tooltip="true" header-align="center" align="center"
          label="产地"></el-table-column>
        <el-table-column prop="impurity" :show-overflow-tooltip="true" header-align="center" align="center"
          label="杂质（%）"></el-table-column>
        <el-table-column prop="roughness" :show-overflow-tooltip="true" header-align="center" align="center"
          label="容重/出糙"></el-table-column>
        <el-table-column prop="imperfectGrain" :show-overflow-tooltip="true" header-align="center" align="center"
          label="不完善粒"></el-table-column>
        <el-table-column prop="waterAbsorption" :show-overflow-tooltip="true" header-align="center" align="center"
          label="面筋吸水率"></el-table-column>
        <el-table-column prop="germinationPercentage" :show-overflow-tooltip="true" header-align="center" align="center"
          label="发芽率"></el-table-column>
        <el-table-column label="操作" width="120" header-align="center" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button @click.native.prevent="editRow(scope.row)" size="mini" class="custom-table-btn">
              编辑
            </el-button>
            <el-button @click.native.prevent="delRow(scope.row)" type="danger" size="mini" class="custom-table-btn">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <table-page :page="searchForm.page" :limit="searchForm.limit" :total="total" @search-table="feach"></table-page>
    </el-card>
    <!-- 编辑  添加-->
    <el-dialog class="custom-dialog" :title="formType?'编辑':'添加'" :visible.sync="dialogVisible" width="820px"
      :append-to-body="true" @close="closeAlert">
      <el-form ref="form" :rules="formRules" :model="form" label-width="90px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="仓房名称" prop="houseId" class="custom-form-item">
              <el-select v-model="form.houseId" placeholder="请选择仓房类型" @change="changeHouseId">
                <el-option v-for="( item,index ) in NotArchivesHouseList" :key="index" :value="item.locaId"
                  :label="item.houseNo" :disabled="item.existence!==0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="仓房类型" prop="cangType" class="custom-form-item">
              <el-select v-model="form.cangType" disabled placeholder="请选择仓房类型">
                <el-option v-for="( item,index ) in typeList" :key="index" :value="item.cangType"
                  :label="item.cangName"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="仓长" class="custom-form-item">
              <el-input v-model="form.cangLong" placeholder="请输入仓长"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="仓宽" class="custom-form-item">
              <el-input v-model="form.cangWide" placeholder="请输入仓宽"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="粮高" class="custom-form-item">
              <el-input v-model="form.liangHeight" placeholder="请输入粮高"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="设计储量/吨" class="custom-form-item">
              <el-input v-model="form.designReserves" placeholder="请输入设计储量（吨）"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="实际储量/吨" class="custom-form-item">
              <el-input v-model="form.actualReserves" placeholder="请输入实际储量（吨）"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="保管员" class="custom-form-item">
              <el-input v-model="form.storekeeper" placeholder="请输入保管员"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="粮食品种" class="custom-form-item">
              <el-input v-model="form.liangVarieties" placeholder="请输入粮食品种"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="储存性质" class="custom-form-item">
              <el-input v-model="form.reservesProperty" placeholder="请输入储存性质"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="入仓时间" class="custom-form-item">
              <el-date-picker v-model="form.enterTime" style="width: 100%;" type="date" placeholder="选择入仓时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="入仓水分" class="custom-form-item">
              <el-input v-model="form.enterDew" placeholder="请输入入仓水分"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="产地" class="custom-form-item">
              <el-input v-model="form.producingArea" placeholder="请输入产地"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="杂质" class="custom-form-item">
              <el-input v-model="form.impurity" placeholder="请输入杂质"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="容重/出糙" class="custom-form-item">
              <el-input v-model="form.roughness" placeholder="请输入容重/出糙"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="不完善粒" class="custom-form-item">
              <el-input v-model="form.imperfectGrain" placeholder="请输入不完善粒"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="面筋吸水率" class="custom-form-item">
              <el-input v-model="form.waterAbsorption" placeholder="请输入面筋吸水率"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="发芽率" class="custom-form-item">
              <el-input v-model="form.germinationPercentage" placeholder="请输入发芽率"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="粮情分析" class="custom-form-item">
              <el-input type="textarea" v-model="form.grainSituationAnalysis" placeholder="请输入粮情分析"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="bottom-btn">
        <el-button type="primary" @click="onSubmit('form')" :loading="loadingAlert" :disabled="loadingAlert">确 定
        </el-button>
        <el-button @click="closeAlert">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  var moment = require('moment');
  export default {
    components: {
      "table-page": () => import('@/components/TablePage')
    },
    data() {
      return {
        typeList: [],
        NotArchivesHouseList: [],
        tableData: [],
        loading: false,
        searchForm: {
          page: 1,
          limit: 10
        },
        total: 0,
        dialogVisible: false,
        formType: false,
        form: {
          id: "",
          houseId: "",
          cangType: "",
          cangLong: "",
          cangWide: "",
          liangHeight: "",
          designReserves: "",
          actualReserves: "",
          storekeeper: "",
          liangVarieties: "",
          reservesProperty: "",
          enterTime: "",
          enterDew: "",
          producingArea: "",
          impurity: "",
          roughness: "",
          imperfectGrain: "",
          waterAbsorption: "",
          germinationPercentage: "",
          grainSituationAnalysis: ""
        },
        loadingAlert: false,
        formRules: {
          houseId: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          cangType: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }]
        }
      }
    },
    created() {
      this.search()
      this.cfCangTypeList();
      this.getNotArchivesHouse();
    },
    methods: {
      //更改选中仓房
      changeHouseId(e) {
        let current = this.NotArchivesHouseList.findIndex(item => item.locaId === e);
        if (current !== -1) this.form.cangType = this.NotArchivesHouseList[current].cangType
      },
      //获取仓房类型
      cfCangTypeList() {
        this.$axios.Get(this.$api.cfCangTypeList, {}).then(res => {
          this.typeList = res.data;
        })
      },
      //获取未添加仓房档案
      getNotArchivesHouse() {
        this.NotArchivesHouseList = [];
        this.$axios.Get(this.$api.getNotArchivesHouse, {}).then(res => {
          this.NotArchivesHouseList = res.data;
        })
      },
      //添加
      add() {
        this.dialogVisible = true;
      },
      editRow(row) {
        this.form = JSON.parse(JSON.stringify(row))
        this.formType = true;
        this.dialogVisible = true;
      },
      //删除
      delRow(e) {
        this.$confirm('此操作将永久删除该数据吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let than = this;
          this.$axios.Post(this.$api.archivesDel, {
            "houseId": e.houseId
          }).then(() => {
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            than.search()
          }).catch((err) => {
            if (err.errMsg) {
              this.$message({
                type: 'error',
                message: err.errMsg
              });
            }
          })
        }).catch(() => {});
      },
      //提交
      onSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.formType ? this.editUser() : this.addUser()
          } else {
            return false;
          }
        });
      },
      //关闭
      closeAlert() {
        this.formType = false;
        this.dialogVisible = false;
        this.$refs["form"].resetFields();
        this.form.id = "";
        this.loadingAlert = false;
        this.getNotArchivesHouse()
      },
      //添加信
      addUser() {
        this.loadingAlert = true
        let param=JSON.parse(JSON.stringify(this.form))
        param["enterTime"]=moment(param.enterTime).format('YYYY-MM-DD')
        this.$axios.Post(this.$api.archivesAdd, param).then(() => {
          this.$message({
            type: 'success',
            message: '添加成功!'
          });
          this.closeAlert();
          this.search()
        }).catch((err) => {
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
          this.loadingAlert = false
        })
      },
      //编辑信息
      editUser() {
        this.loadingAlert = true
        let param=JSON.parse(JSON.stringify(this.form))
        param["enterTime"]=moment(param.enterTime).format('YYYY-MM-DD')
        this.$axios.Post(this.$api.archivesModify, param).then(() => {
          this.$message({
            type: 'success',
            message: '修改成功!'
          });
          this.closeAlert();
          this.search()
        }).catch((err) => {
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
          this.loadingAlert = false
        })
      },
      //搜索
      search() {
        this.getArchivesPage(1, this.searchForm.limit)
      },
      //分页
      feach(e) {
        this.getArchivesPage(e.page, e.limit)
      },
      //获取仓房信息
      getArchivesPage(page, limit) {
        this.loading = true;
        this.$axios.Get(this.$api.getArchivesPage, {
          page: page,
          limit: limit
        }).then(res => {
          this.tableData = res.data;
          this.searchForm.limit = limit
          this.searchForm.page = page
          this.total = res.count
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        })
      }
    }
  }
</script>

<style>
</style>
